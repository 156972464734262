<template>
  <div />
</template>

<script>
import { mapMutations } from 'vuex';
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { toggleLoading, updateProgressBar } from '@/plugins/vuex/mutationTypes';
import { accessToken, companyId } from '@/services/cookies';
import { SurveyStatus } from '@/utils/constants';

export default {
  name: 'SurveyTechPage',
  inject: ['Names'],

  data() {
    return {
      currentUserSurveySlug: '',
    };
  },

  computed: {
    surveySlug() {
      return this.$route.params.slug;
    },
    accessToken() {
      return accessToken.get();
    },
    companyId() {
      return companyId.get();
    },
    isSurveyMultilingual() {
      return this.languages.length > 1;
    },
    isTestingFulfillment() {
      return this.$route.query.testing;
    },
  },

  created() {
    this.initSurvey();
  },

  methods: {
    ...mapMutations([toggleLoading, updateProgressBar]),
    async initSurvey() {
      this[toggleLoading](true);

      try {
        const { data: welcomeResponse } = await apiService.surveyByUserSlugWelcomeGet(this.surveySlug);
        const {
          surveyName, surveyStatus, authorizationRequired, orgstructureRequired, orgstructurePageOrder,
        } = welcomeResponse;

        this[updateProgressBar]({ surveyName });
        if (surveyStatus === SurveyStatus.FINISHED) {
          this.$router.push({
            name: this.Names.R_USER_SURVEY_FINISHED,
            params: { slug: this.surveySlug },
          });
          return;
        }

        if (surveyStatus === SurveyStatus.PUBLISHED) {
          this.$router.push({
            name: this.Names.R_SURVEY_NOT_ACTIVE,
            params: { slug: this.surveySlug },
          });
          return;
        }

        if (authorizationRequired && !this.accessToken) {
          const url = new URL(`/?back=${window.location.href}`, process.env.VUE_APP_GATEKEEPER_ADDRESS);
          window.location.replace(url.href);
          return;
        }
        const options = authorizationRequired ? { headers: { Authorization: this.accessToken } } : {};
        const { data } = await apiService.surveyByUserSlugCreate(this.surveySlug, options);
        this.currentUserSurveySlug = data?.slug;

        if (authorizationRequired && !data?.slug) {
          this.$router.push({
            name: this.Names.R_USER_SURVEY_ANOTHER_ORGANIZATION,
            params: { slug: this.surveySlug },
          });
          return;
        }

        const { data: languages } = await apiService.surveyLanguagesGet(this.currentUserSurveySlug);
        if (languages.length > 1) {
          this.$router.push({
            name: this.Names.R_USER_SURVEY_LANGUAGES,
            params: { slug: this.currentUserSurveySlug },
          });
          return;
        }
        if (orgstructureRequired && orgstructurePageOrder === 0) {
          this.$router.push({
            name: this.Names.R_SURVEY_ORGSTRUCTURE,
            params: { slug: this.currentUserSurveySlug },
            query: this.isTestingFulfillment ? { testing: true } : {},
          });
          return;
        }
        this.$router.push({
          name: this.Names.R_USER_SURVEY_PAGE,
          params: { slug: this.currentUserSurveySlug },
          query: this.isTestingFulfillment ? { testing: true } : {},
        });
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
  },
};
</script>
